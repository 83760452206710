.dropdown-menu {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns for each section */
    gap: 20px;
    padding: 20px;
    background-color: black;
    position: absolute;
    top: 100%;
    left:-80px; /* Align dropdown to center */
    transform: translateX(-50%); /* Center dropdown */
    z-index: 10;
    width: 900px;
}
.navbar li{
  /* font-family: "Bree Serif", serif; */
  font-size: 15px;
}
.dropdown-menu ul {
    list-style: none;
    padding: 0;
}

.dropdown-menu h3 a {
    color: #cba33e; /* Gold color */
    margin-bottom: 10px;
    font-weight: bold;
}

.dropdown-menu a {
    text-decoration: none;
    color: white;
    font-size: 0.9rem;
    display: block;
    padding: 5px 0;
}

.dropdown-menu a:hover {
    color: #cba33e; /* Hover effect with gold color */
}

/* Add a line between columns only in the Jewellery section */
.jewellery-dropdown .dropdown-menu {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Adjust based on the number of columns */
    gap: 20px; /* Spacing between the columns */
    padding: 20px;
    background-color: black;
    color: white;
  }
  
  .jewellery-dropdown .dropdown-menu > div {
    padding-left: 10px;
    /* border-left: 1px solid #cba33e; Line between columns */
  }
  
  .jewellery-dropdown .dropdown-menu > div:first-child {
    border-left: none; /* Remove left border for the first column */
  }
  
  .jewellery-dropdown .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .jewellery-dropdown .dropdown-menu ul li {
    margin-bottom: 5px;
  }
  
  .jewellery-dropdown .dropdown-menu ul li a {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
  }
  
  .jewellery-dropdown .dropdown-menu ul li a:hover {
    color: #bd8c39; /* Hover effect color */
  }
 

.dropdown-menu.active {
    display: block; /* Show when active */
}

@media (max-width: 640px) {

    .lg:hidden {
        display: block; /* Show mobile menu */
    }

    .lg:flex {
        display: none; /* Hide desktop menu on mobile */
    }
}
