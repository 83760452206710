/* Base styles */
.giva-container {
    background-color: black; /* Black background */
    color: white; /* White text color */
    text-align: center;
    padding: 20px;
  }
  
  .giva-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin-top: 20px;
  }
  
  .giva-item {
    position: relative;
    flex-basis: 100%; /* Full width by default */
  }
  
  .giva-image-wrapper {
    position: relative;
  }
  
  .giva-image {
    width: 100%;
    border-radius: 10px; /* Rounded corners */
  }
  
  .giva-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  p {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  
  /* Responsive breakpoints */
  
  /* For screens larger than 576px */
  @media (min-width: 576px) {
    .giva-item {
      flex-basis: 48%; /* Two items per row */
    }
  }
  
  /* For screens larger than 768px */
  @media (min-width: 768px) {
    .giva-item {
      flex-basis: 45%; /* Slightly more space between items */
    }
  }
  
  /* For screens larger than 992px */
  @media (min-width: 992px) {
    .giva-item {
      flex-basis: 40%; /* Smaller columns */
    }
  }
  
  /* For screens larger than 1200px */
  @media (min-width: 1200px) {
    .giva-item {
      flex-basis: 35%; /* Adjust column width */
    }
  }
  
  /* For screens larger than 1400px */
  @media (min-width: 1400px) {
    .giva-item {
      flex-basis: 30%; /* Larger screens get narrower items */
    }
  }
  