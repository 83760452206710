/* Default styling for all screen sizes */
.map-container {
    width: 100%;
    height: 200px; /* Default height */
    overflow: hidden;
    position: relative;
  }
  
  .responsive-iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  /* Container styling */
.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    background-color: #1d1d1d;
    color: #fff;
    font-family: 'Arial', sans-serif;
  }
  
  /* Contact info */
  .contact-info {
    width: 40%;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
   
  }
  
  .icon {
    font-size: 24px;
    color: #c1a36e;
    margin-right: 15px;
    /* margin-top: -360px */
  }
  .opening-hours-container {
    display: flex;
    align-items: center;
    padding: 10px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
    
  }
  
  /* Icon styling */
  .icon {
    margin-right: 15px;
    font-size: 1.8rem;
    color: #4CAF50;
  }
  
  /* Heading */
  .heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Each time entry */
  .time-entry {
    margin: 5px 0;
    font-size: 1rem;
    color: #ffffff;
 
  }
  
  .contact-item h3 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .contact-item p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px;
  }
  
  /* Contact form styling */
  .contact-form {
    width: 50%;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-row input {
    width: 48%;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    background-color: #2d2d2d;
    border: 1px solid #c1a36e;
    color: #fff;
  }
  
  input::placeholder, textarea::placeholder {
    color: #aaa;
  }
  
  textarea {
    resize: none;
  }
  
  button {
    background-color: transparent;
    color: #c1a36e;
    padding: 10px 20px;
    border: 2px solid #c1a36e;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #c1a36e;
    color: #1d1d1d;
  }
  
  /* Responsive breakpoints */
  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
  
    .contact-info, .contact-form {
      width: 100%;
    }
  
    .contact-form {
      margin-top: 30px;
    }
  
    .form-row {
      flex-direction: column;
    }
  
    .form-row input {
      width: 100%;
    }
  }
  
  
  /* Breakpoints for responsive resizing */
  @media (min-width: 576px) {
    .map-container {
      height: 400px; /* Height for 576px screen width */
    }
  }
  
  @media (min-width: 768px) {
    .map-container {
      height: 500px; /* Height for 768px screen width */
    }
  }
  
  @media (min-width: 992px) {
    .map-container {
      height: 600px; /* Height for 992px screen width */
    }
  }
  
  @media (min-width: 1200px) {
    .map-container {
      height: 700px; /* Height for 1200px screen width */
    }
  }
  
  @media (min-width: 1400px) {
    .map-container {
      height: 800px; /* Height for 1400px screen width */
    }
  }
  