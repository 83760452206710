/* WelcomeSection.css */

.welcome-section {
  position: relative;
  width: 100%;
  height: 100vh;
  /* background-image: url('/afrodubai/src/IMAGE/BACKGROUNDIMAGES.png');  */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-section img {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-content {
  text-align: center;
  color: #ffd700;
  /* Gold color */

  font-family: 'Playfair Display', serif;
  /* Elegant font */
}

.welcome-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  font-family: "Abril Fatface", serif;
}

.welcome-content p {
  font-size: 3.5rem;
  font-family: "Bebas Neue", sans-serif;
  /* font-weight: bold; */
  margin-top: -20px;
  margin-bottom: 0.5rem;

}

.welcome-content .location {
  font-size: 1.5rem;
  letter-spacing: 0.45rem;
  color: white;
  font-weight: 300;
  margin-top: -10px;
}

/* About Section Styling */
.about-section {
  background-color: #222;
  /* Dark background */
  color: #fff;
  padding: 50px 0;
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Left Text Section Styling */
.about-text {
  flex: 1;
  margin-right: 40px;
}

.about-text h1 {
  font-family: "Merienda", cursive;
  /* font-size: 2.5rem; */
  font-size: 25px;
  color: #ffbf00;
  /* Gold Color */
  margin-bottom: 20px;
}

.about-text p {
  font-family: "Kanit", sans-serif;
  /* font-size: 1.2rem; */
  line-height: 1.8;
}

.about-text ul {
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
}

.about-text ul li {
  font-size: 18px;
  /* font-size: 1rem; */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: "Kanit", sans-serif;
}

.about-text ul li::before {
  content: '✔';
  color: #ffd500;
  margin-right: 10px;
}

/* Right Image Section Styling */
.about-image {
  text-align: center;
  flex: 1;

}

.about-image img {
  max-width: 550px;
  border-radius: 10px;
  /* margin-top: 150px; */
  /* max-width: 450px; */
}

.about-image h3 {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 15px;
}

.about-image p {
  font-size: 1rem;
  color: #ccc;
}

/* why-choos */
.jewellery-section {
  text-align: center;
  background-color: black;
  color: white;
  padding: 30px;
}

.jewellery-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
}

.jewellery-item {
  text-align: center;
  color: #fff;
}

.jewellery-item img {
  width: 300px;
  height: 300px;
}

.jewellery-item p {
  margin-top: 10px;
  font-size: 1.2em;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.jewellery-icon {
  width: 60px;
  height: 60px;
}

.jewellery-section h3 {
  font-size: 30px;
  font-family: "Alkatra", system-ui;
  letter-spacing: 2px;
  margin-bottom: 0;
  color: #ddd;
}

.jewellery-section h2 {
  font-size: 2em;
  font-family: "Merienda", cursive;
  font-weight: bold;
  margin-bottom: 20px;
  color: #eea010;
}
.jewellery-item p{
  font-family: "Itim", cursive;
}
.giva-container h2 {
  font-size: 35px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 5px;
  position: relative; /* To position the ::after element correctly */
}

.giva-container h2::after {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: 0;
  left: 50%; /* Center the underline based on the <h2> element */
  transform: translateX(-50%); /* Adjust for screen responsiveness */
}
/* Media Queries for Responsiveness */

/* For screens 1200px and above */
@media (min-width: 1200px) {
  .welcome-content h1 {
    font-size: 2.8rem;
  }

  .welcome-content h2 {
    font-size: 4rem;
  }

  .welcome-content .location {
    font-size: 1.6rem;
  }

  /* intro-sec */
  .about-container {
    flex-direction: row;
  }

  /* .about-text h1 {
        font-size: 2.8rem;
      }
     */
  .about-text p {
    font-size: 1.2rem;
  }

  /* .about-image img {
        max-width: 300px;
      } */

  .about-image h3 {
    font-size: 1.5rem;
  }

  /* jewellery-sec */

  .jewellery-icon {
    width: 70px;
    height: 70px;
  }

  .jewellery-section h2 {
    font-size: 2.5em;
  }
}


/* For screens 992px and below */
@media (max-width: 992px) {
  .welcome-content h1 {
    font-size: 2.2rem;
  }

  .welcome-content h2 {
    font-size: 3.2rem;
  }

  .welcome-content .location {
    font-size: 1.4rem;
  }

  /* intro-sec */

  .about-container {
    flex-direction: row;
  }

  .about-text {
    margin-right: 20px;
  }

  .about-text h1 {
    font-size: 3.2rem;
  }

  .about-text p {
    font-size: 1.1rem;
  }

  .about-image img {
    max-width: 350px;
  }

  .about-image h3 {
    font-size: 1.4rem;
  }

  /* jewellery-sec */

  .jewellery-items {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .jewellery-icon {
    width: 65px;
    height: 65px;
  }

  .jewellery-section h2 {
    font-size: 2.2em;
  }
}


/* For screens 768px and below */
@media (max-width: 768px) {
  .welcome-content h1 {
    font-size: 2rem;
  }

  .welcome-content h2 {
    font-size: 3rem;
  }

  .welcome-content .location {
    font-size: 1.3rem;
  }

  /* introsec */
  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-text h1 {
    font-size: 2rem;
  }

  .about-text p {
    font-size: 1.1rem;
  }

  .about-text ul li {
    font-size: 0.95rem;
  }

  .about-image img {
    max-width: 420px;
    margin-top: 10px;
  }

  .about-image h3 {
    font-size: 1.3rem;
  }

  .jewellery-items {
    flex-direction: row;
    justify-content: space-between;
  }

  .jewellery-icon {
    width: 60px;
    height: 60px;
  }

  .jewellery-section h2 {
    font-size: 2em;
  }

  .giva-container h2 {
    font-size: 28px;
    letter-spacing: 3px;
  }

  .giva-container h2::after {
    width: 30%; /* Adjust for smaller screens */
    left: 50%; /* Center underline on smaller screens as well */
  }


}

@media (max-width: 480px) {
  .giva-container h2 {
    font-size: 24px;
    letter-spacing: 2px;
  }

  .giva-container h2::after {
    width: 40%; /* Adjust for mobile devices */
    left: 50%; /* Center underline on mobile screens as well */
  }
}

/* For screens 576px and below */
@media (max-width: 576px) {
  .welcome-content h1 {
    font-size: 1.8rem;
  }

  .welcome-content h2 {
    font-size: 2.5rem;
  }

  .welcome-content .location {
    font-size: 1.2rem;
  }

  /* introsec */

  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-text {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-text h1 {
    font-size: 1.8rem;
  }

  .about-text p {
    font-size: 1rem;
  }

  .about-text ul li {
    font-size: 0.9rem;
  }

  .about-image img {
    max-width: 350px;
    margin-top: -10px;
  }

  .about-image h3 {
    font-size: 1.2rem;
  }

  /* jewellery-sec */

  .jewellery-items {
    flex-direction: column;
  }

  .jewellery-item {
    width: 100%;
    /* Each item takes up full width */
    margin-bottom: 20px;
    /* Add some spacing between items */
  }

  .jewellery-section h2 {
    font-size: 1.5em;
  }

  .jewellery-icon {
    width: 50px;
    height: 50px;
  }
}