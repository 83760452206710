/* App.css */

/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000;  /* Black background */
  color: #fff;             /* White text */
}

.hero-section img {
  width: 100%;
  margin-top: 50px;
}

/* Product Section */
.product-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #000;  /* Black background for product section */
}

.product-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff; /* White text */
}

.product-section p {
  max-width: 700px;
  margin: 0 auto 40px;
  font-size: 1.1rem;
  color: #ccc; /* Light gray text */
}

/* Product Grid */
.product-grid {
  display: flex;
  justify-content: center;
  gap: 20px; /* Reduced gap for smaller screens */
  flex-wrap: wrap;
}

/* Product Card */
.product-card {
  width: 100%; /* Default to full width */
  max-width: 200px; /* Maximum width for larger screens */
  text-align: center;
}

.product-card img {
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
}

.product-card h3 {
  color: #fff; 
}

/* Learn More Button */
.learn-more-btn {
  background-color: #fff; 
  color: #000;            
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
}

.learn-more-btn:hover {
  background-color: #f5c518; 
  color: #000;
}

/* Responsive Breakpoints */
@media (max-width: 1400px) {
  .product-card {
      max-width: 180px;
  }
}

@media (max-width: 1200px) {
  .product-card {
      max-width: 160px;
  }
}

@media (max-width: 992px) {
  .hero-content h1 {
      font-size: 1.8rem;
  }

  .hero-buttons {
      flex-direction: column;
  }

  .product-card {
      max-width: 150px;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
      font-size: 1.6rem;
  }

  .hero-section {
      padding: 40px;
  }

  .product-section {
      padding: 40px 20px;
  }

  .product-card {
      max-width: 120px;
  }
}

@media (max-width: 576px) {
  .hero-content h1 {
      font-size: 1.4rem;
  }

  .hero-section {
      padding: 30px;
  }

  .product-section {
      padding: 30px 20px;
  }

  .product-card {
      max-width: 100%; /* Full width on smaller screens */
      padding: 0 10px; /* Padding for small screens */
  }

  .hero-image {
      max-width: 60%;
  }
}
